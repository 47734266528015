import React, { useState } from "react";
import FormBanner from "../components/FormBanner";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import { Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import Logo from "../components/Logo";
import Statistics from "../components/Statistics";
import ServiceLocation from "../components/ServiceLocation";
import NavigateBlockV3 from "../components/NavigateBlockV3";
import SingleTestimonial from "../components/SingleTestimonial";
import CampaignInfo from "../components/CampaignInfo";
import "../styles/main.scss";
import ogIMage from "../images/og.png";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import ImageSlider from "../components/ImageSlider";
import SalesforceInfo from "../components/SalesforceInfo";
import SalesforceContactBlock from "../components/SalesforceContactBlock";
import SalesforceExpertise from "../components/Salesforce-expertise";
import Testimonials from "../components/Testimonials";
import Faq from "../components/faq";
// import EngineeringImgSlider from "../components/EngineeringImgSlider";
import EngineeringLeadForm from "../components/EngineeringLeadForm";
import { CSSTransition } from "react-transition-group";
import CadImageGrid from "../components/Cad-ImageGrid";
import SedinCadLogo from "../images/cad-service/sedin-cad-logo.svg";
import SedinCadLogoBlack from "../images/cad-service/sedin-cad-logo-black.svg";
import StructuralOne from "../images/cad-service/structural-design-01-updated.webp";
import StructuralTwo from "../images/cad-service/structural-design-02-new.png";
import StructuralThree from "../images/cad-service/structural-design-03-updated.png";
import StructuralFour from "../images/cad-service/structural-design-04-updated.webp";
import StructuralFive from "../images/cad-service/structural-design-05-new.png";
import StructuralSix from "../images/cad-service/structural-design-06-updated.webp";
import IndustrialOne from "../images/cad-service/industrial-design-01-new.png";
import IndustrialTwo from "../images/cad-service/industrial-design-02-new.jpeg";
import IndustrialThree from "../images/cad-service/industrial-design-03-new.png";
import IndustrialFour from "../images/cad-service/industrial-design-04-new.jpg";
import IndustrialFive from "../images/cad-service/industrial-design-05-new.png";
import IndustrialSix from "../images/cad-service/industrial-design-06-new.jpg";
import ProductDesignOne from "../images/cad-service/product-design-01-new.png";
import ProductDesignTwo from "../images/cad-service/product-design-02-new.png";
import ProductDesignThree from "../images/cad-service/product-design-03-new.png";
import ProductDesignFour from "../images/cad-service/product-design-04-new.png";
import ProductDesignFive from "../images/cad-service/product-design-05-new.png";
import ProductDesignSix from "../images/cad-service/product-design-06-new.png";
import EngModalOne from "../images/cad-service/staff-aug.svg";
import EngModalTwo from "../images/cad-service/team.svg";
import EngModalThree from "../images/cad-service/design.svg";
import IndustryOne from "../images/cad-service/au-steel-agriculture.svg";
import IndustryTwo from "../images/cad-service/au-steel-food-b.svg";
import IndustryThree from "../images/cad-service/au-steel-heavy-machinery.svg";
import IndustryFour from "../images/cad-service/au-steel-industrial-auto.svg";
import IndustryFive from "../images/cad-service/au-steel-structure.svg";
import IndustrySix from "../images/cad-service/au-steel-automotive.svg";
import IndustrySeven from "../images/cad-service/au-steel-oil-gas.svg";
import IndustryEight from "../images/cad-service/au-steel-water.svg";
import IndustryNine from "../images/cad-service/au-steel-packaging.svg";
import IndustryTen from "../images/cad-service/au-steel-machinary.svg";
import IndustryEleven from "../images/cad-service/au-steel-maritime.svg";
import IndustryTwelve from "../images/cad-service/au-steel-power.svg";
import IndustryThirteen from "../images/cad-service/au-steel-medical.svg";
import IndustryFourteen from "../images/cad-service/au-steel-bridge.svg";
import CadServiceForm from "../components/Cad-serviceForm";
import { Helmet } from "react-helmet";
const Campaign = ({ data, location }) => {
    const [showModal, setShowModal] = useState(false);
    const handleModel = () => {
        setShowModal(true);
    };
    const bgImage = data.pageData.banner?.gatsbyImageData?.images.fallback.src;
    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.UL_LIST]: (node, children) => {
                return <ul>{children}</ul>;
            },
            [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
            [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        },
    };
    let engineeringCad = location.pathname.includes("engineering-cad");
    let salesforce = location.pathname.includes("salesforce");
    let engineering = location.pathname.includes("engineering");
    let steelStructure = location.pathname.includes("steel-structure");
    let eamCampaginPage = location.pathname.includes("eam360");
    let industricalAutomation = location.pathname.includes("industrial-automation");
    return (
        <>
            {steelStructure ? (
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
            ) : (
                <></>
            )}
            <Seo title={data.pageData.metTitle} url={location.href} image={ogIMage} />
            <section>
                <div
                    style={{
                        background: `url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                    }}
                    className="campaign-banner"
                >
                    <div className="container">
                        <div className="site-logo">
                            <Link to="/" className="logo-inner">
                                {salesforce || eamCampaginPage ? (
                                    <>
                                        <div className="ring">
                                            <Logo />
                                        </div>
                                        <svg
                                            className="logo"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="100"
                                            height="32.338"
                                            viewBox="0 0 100 32.338"
                                        >
                                            <path
                                                d="M16.473-85.474c5.047,0,10.92-2.756,10.92-9.5,0-5.937-4.22-7.337-9.542-9.415-3.542-1.378-5.217-2.184-5.2-4.114.021-1.951,1.59-3.1,3.711-3.1,1.93,0,4.156.933,6.722,3.033l3.52-4.6a16,16,0,0,0-10.391-3.881c-5.535.064-9.9,3.86-9.9,8.822,0,5.81,4.665,7.973,9.331,9.606,3.732,1.294,5.365,2.1,5.365,4.368,0,1.908-1.484,3.265-4.432,3.265A10.555,10.555,0,0,1,8.9-94.846L5.234-90.329a15.553,15.553,0,0,0,11.238,4.856ZM49.3-96.628c0-6.213-4.177-10.772-10.412-10.772-6.022,0-10.518,4.5-10.518,10.942a10.515,10.515,0,0,0,10.92,10.984,11.288,11.288,0,0,0,9.161-4.008l-3.69-3.435a6.282,6.282,0,0,1-5.068,2.4,5.341,5.341,0,0,1-5.407-4.071H49.154a10.808,10.808,0,0,0,.148-2.036ZM39.082-102.4a4.5,4.5,0,0,1,4.581,3.711H34.311A4.671,4.671,0,0,1,39.082-102.4Zm27.55-15.415v13.168h-.106a8.216,8.216,0,0,0-6.4-2.757c-5.683,0-9.988,4.58-9.988,11.027,0,6.552,4.283,10.9,10.157,10.9A7.852,7.852,0,0,0,66.781-88.4h.127v2.566h5.407v-31.977Zm-5.3,27.248c-3.181,0-5.429-2.354-5.429-5.789,0-3.626,2.4-5.937,5.45-5.937a5.6,5.6,0,0,1,5.577,5.937,5.511,5.511,0,0,1-5.6,5.789Zm17.307-19.466a3.5,3.5,0,0,0,3.52-3.478,3.512,3.512,0,0,0-3.52-3.456,3.5,3.5,0,0,0-3.52,3.456A3.489,3.489,0,0,0,78.639-110.029ZM75.8-85.835H81.48V-107.04H75.8ZM97.537-107.4a8.838,8.838,0,0,0-6.807,3.1h-.064v-2.735H84.983v21.205h5.683V-96.5c0-3.775,1.93-5.789,4.814-5.789,2.63,0,4.071,1.251,4.071,5.3v11.175h5.683v-12.6c0-6.531-3.16-8.991-7.7-8.991Z"
                                                transform="translate(-5.234 117.812)"
                                            />
                                        </svg>
                                    </>
                                ) : steelStructure || engineering || industricalAutomation ? (
                                    <img src={SedinCadLogoBlack} alt="sedin-logo-black" />
                                ) : (
                                    <img src={SedinCadLogo} alt="sedin-logo" />
                                )}
                            </Link>

                            {steelStructure || engineering || industricalAutomation ? (
                                <div className="flex flex-ai-c">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 30 30"
                                        fill="none"
                                    >
                                        <g clip-path="url(#clip0_1_2509)">
                                            <path
                                                d="M18.6797 4.6875C20.2699 5.11427 21.7198 5.95178 22.884 7.11599C24.0482 8.2802 24.8857 9.73014 25.3125 11.3203"
                                                stroke="#AF8169"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M17.707 8.30859C18.6634 8.56259 19.5357 9.0649 20.2354 9.76462C20.9351 10.4643 21.4374 11.3366 21.6914 12.293"
                                                stroke="#AF8169"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M10.8398 14.625C11.8046 16.6172 13.4155 18.224 15.4102 19.1836C15.5573 19.2533 15.72 19.2834 15.8824 19.2711C16.0447 19.2588 16.201 19.2044 16.3359 19.1133L19.2656 17.1563C19.3951 17.0685 19.5446 17.0149 19.7004 17.0006C19.8561 16.9862 20.013 17.0116 20.1563 17.0742L25.6406 19.4297C25.8281 19.5077 25.9847 19.6452 26.0863 19.8209C26.1879 19.9967 26.229 20.201 26.2031 20.4024C26.0293 21.7591 25.3671 23.006 24.3404 23.9097C23.3137 24.8135 21.9928 25.3122 20.625 25.3125C16.3981 25.3125 12.3443 23.6334 9.35549 20.6445C6.36663 17.6557 4.6875 13.6019 4.6875 9.37501C4.68781 8.00719 5.1865 6.68635 6.09026 5.65964C6.99403 4.63293 8.24093 3.97071 9.59766 3.79688C9.79903 3.77102 10.0033 3.81206 10.1791 3.9137C10.3548 4.01534 10.4923 4.17194 10.5703 4.35938L12.9258 9.85548C12.987 9.99651 13.0127 10.1504 13.0004 10.3037C12.9881 10.457 12.9384 10.6049 12.8555 10.7344L10.8984 13.7109C10.8113 13.8456 10.7602 14.0003 10.7499 14.1603C10.7397 14.3204 10.7706 14.4804 10.8398 14.625Z"
                                                stroke="#AF8169"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_2509">
                                                <rect width="30" height="30" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <a
                                        className="contact steel-structure-call"
                                        href="tel: +61 411 333 390"
                                    >
                                        Call Us: +61 411 333 390
                                    </a>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <FormBanner
                            title={data.pageData.title}
                            description={data.pageData.description.description}
                            logo={data.pageData.logo}
                            formTitle={data.pageData.formHeading}
                            locationSlug={salesforce}
                            engineeringSlug={engineering}
                            campaignDescPoints={data.pageData.campaignDescPoints}
                            engineeringCad={engineeringCad}
                            steelStructure={steelStructure}
                            handleModel={handleModel}
                            eamCampaginPage={eamCampaginPage}
                            industricalAutomation={industricalAutomation}
                        />
                    </div>
                </div>
                {(steelStructure && engineering && industricalAutomation) !==
                    (!engineeringCad && !salesforce && !eamCampaginPage) && (
                    <div className="container container--pb" style={{ paddingTop: "80px" }}>
                        <div className="client-block">
                            <div className="client-block__text"></div>
                            <div className="engineering-client-block__grid">
                                <p className="text-p3 text-clr-secondary text-fw-regular">
                                    Trusted by
                                </p>
                                {data?.pageData?.campaignClientLogo?.map((logo, i) => (
                                    <GatsbyImage
                                        key={i}
                                        image={logo?.gatsbyImageData}
                                        alt={logo?.title}
                                        className="engineering-client-logo"
                                        loading="lazy"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </section>
            {engineeringCad && (
                <>
                    <section className="campaign-social-proof">
                        <div className="container">
                            <h1 className="text-h1 gap-4x header text-fw-medium">
                                Stay ahead of the competition
                            </h1>
                            <div className="campaign-social-proof__grid">
                                <div className="campaign-social-proof__grid-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="80"
                                        height="80"
                                        viewBox="0 0 124 124"
                                        fill="none"
                                        className="gap-05x"
                                    >
                                        <path
                                            d="M62.0002 10.3334C90.5357 10.3334 113.667 33.4645 113.667 62C113.667 90.5355 90.5357 113.667 62.0002 113.667C33.4647 113.667 10.3335 90.5355 10.3335 62C10.3335 33.4645 33.4647 10.3334 62.0002 10.3334ZM62.0002 18.0834C50.3527 18.0834 39.1824 22.7103 30.9464 30.9463C22.7104 39.1822 18.0835 50.3526 18.0835 62C18.0835 73.6475 22.7104 84.8178 30.9464 93.0538C39.1824 101.29 50.3527 105.917 62.0002 105.917C73.6476 105.917 84.818 101.29 93.0539 93.0538C101.29 84.8178 105.917 73.6475 105.917 62C105.917 50.3526 101.29 39.1822 93.0539 30.9463C84.818 22.7103 73.6476 18.0834 62.0002 18.0834ZM55.5418 69.44L78.6368 46.345C79.3292 45.6581 80.2551 45.2575 81.2298 45.2232C82.2045 45.1888 83.1563 45.5233 83.8952 46.1599C84.6341 46.7964 85.1059 47.6882 85.2162 48.6572C85.3266 49.6263 85.0675 50.6013 84.4907 51.3877L84.1135 51.8217L58.2802 77.655C57.6241 78.3113 56.7542 78.7103 55.8288 78.7795C54.9034 78.8487 53.9839 78.5836 53.2375 78.0322L52.8035 77.655L39.8868 64.7384C39.1999 64.046 38.7993 63.1201 38.7649 62.1454C38.7306 61.1707 39.0651 60.2189 39.7017 59.48C40.3382 58.741 41.23 58.2693 42.199 58.159C43.1681 58.0486 44.1431 58.3077 44.9295 58.8845L45.3635 59.2617L55.5418 69.44Z"
                                            fill="#0000FF"
                                        />
                                    </svg>
                                    <h3 className="text-h3 text-fw-medium text-clr-primary">
                                        Expert CAD team
                                    </h3>
                                </div>
                                <div className="campaign-social-proof__grid-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="80"
                                        height="80"
                                        viewBox="0 0 124 124"
                                        fill="none"
                                        className="gap-05x"
                                    >
                                        <path
                                            d="M62.0002 10.3334C90.5357 10.3334 113.667 33.4645 113.667 62C113.667 90.5355 90.5357 113.667 62.0002 113.667C33.4647 113.667 10.3335 90.5355 10.3335 62C10.3335 33.4645 33.4647 10.3334 62.0002 10.3334ZM62.0002 18.0834C50.3527 18.0834 39.1824 22.7103 30.9464 30.9463C22.7104 39.1822 18.0835 50.3526 18.0835 62C18.0835 73.6475 22.7104 84.8178 30.9464 93.0538C39.1824 101.29 50.3527 105.917 62.0002 105.917C73.6476 105.917 84.818 101.29 93.0539 93.0538C101.29 84.8178 105.917 73.6475 105.917 62C105.917 50.3526 101.29 39.1822 93.0539 30.9463C84.818 22.7103 73.6476 18.0834 62.0002 18.0834ZM55.5418 69.44L78.6368 46.345C79.3292 45.6581 80.2551 45.2575 81.2298 45.2232C82.2045 45.1888 83.1563 45.5233 83.8952 46.1599C84.6341 46.7964 85.1059 47.6882 85.2162 48.6572C85.3266 49.6263 85.0675 50.6013 84.4907 51.3877L84.1135 51.8217L58.2802 77.655C57.6241 78.3113 56.7542 78.7103 55.8288 78.7795C54.9034 78.8487 53.9839 78.5836 53.2375 78.0322L52.8035 77.655L39.8868 64.7384C39.1999 64.046 38.7993 63.1201 38.7649 62.1454C38.7306 61.1707 39.0651 60.2189 39.7017 59.48C40.3382 58.741 41.23 58.2693 42.199 58.159C43.1681 58.0486 44.1431 58.3077 44.9295 58.8845L45.3635 59.2617L55.5418 69.44Z"
                                            fill="#0000FF"
                                        />
                                    </svg>
                                    <h3 className="text-h3 text-fw-medium text-clr-primary">
                                        Quality guaranteed
                                    </h3>
                                </div>
                                <div className="campaign-social-proof__grid-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="80"
                                        height="80"
                                        viewBox="0 0 124 124"
                                        fill="none"
                                        className="gap-05x"
                                    >
                                        <path
                                            d="M62.0002 10.3334C90.5357 10.3334 113.667 33.4645 113.667 62C113.667 90.5355 90.5357 113.667 62.0002 113.667C33.4647 113.667 10.3335 90.5355 10.3335 62C10.3335 33.4645 33.4647 10.3334 62.0002 10.3334ZM62.0002 18.0834C50.3527 18.0834 39.1824 22.7103 30.9464 30.9463C22.7104 39.1822 18.0835 50.3526 18.0835 62C18.0835 73.6475 22.7104 84.8178 30.9464 93.0538C39.1824 101.29 50.3527 105.917 62.0002 105.917C73.6476 105.917 84.818 101.29 93.0539 93.0538C101.29 84.8178 105.917 73.6475 105.917 62C105.917 50.3526 101.29 39.1822 93.0539 30.9463C84.818 22.7103 73.6476 18.0834 62.0002 18.0834ZM55.5418 69.44L78.6368 46.345C79.3292 45.6581 80.2551 45.2575 81.2298 45.2232C82.2045 45.1888 83.1563 45.5233 83.8952 46.1599C84.6341 46.7964 85.1059 47.6882 85.2162 48.6572C85.3266 49.6263 85.0675 50.6013 84.4907 51.3877L84.1135 51.8217L58.2802 77.655C57.6241 78.3113 56.7542 78.7103 55.8288 78.7795C54.9034 78.8487 53.9839 78.5836 53.2375 78.0322L52.8035 77.655L39.8868 64.7384C39.1999 64.046 38.7993 63.1201 38.7649 62.1454C38.7306 61.1707 39.0651 60.2189 39.7017 59.48C40.3382 58.741 41.23 58.2693 42.199 58.159C43.1681 58.0486 44.1431 58.3077 44.9295 58.8845L45.3635 59.2617L55.5418 69.44Z"
                                            fill="#0000FF"
                                        />
                                    </svg>
                                    <h3 className="text-h3 text-fw-medium text-clr-primary">
                                        Fast turnaround
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="campaign-process">
                        <div className="container container--first">
                            <h2 className="text-h2 text-fw-medium text-clr-primary gap-4x">
                                How the process works
                            </h2>
                            <div className="campaign-process__grid">
                                <div className="campaign-process__grid-item">
                                    <div className="text-p2 text-fw-medium text-clr-secondary">
                                        Step 1
                                    </div>
                                    <div className="text-h3 text-clr-primary text-fw-medium">
                                        Let's meet
                                    </div>
                                    <div className="text-p2 text-clr-secondary text-fw-regular">
                                        Our Melbourne-based team schedules a 15 minute call with key
                                        stakeholder to meet the team and learn about your business
                                        and your engineering requirements.
                                    </div>
                                </div>
                                <div className="campaign-process__grid-item">
                                    <div className="text-p2 text-fw-medium text-clr-secondary">
                                        Step 2
                                    </div>
                                    <div className="text-h3 text-clr-primary text-fw-medium">
                                        Brief a free trial project.
                                    </div>
                                    <div className="text-p2 text-clr-secondary text-fw-regular">
                                        Select any job, but we usually recommend something around 20
                                        hours so you maximise the value of our free trial. You
                                        upload the files to our secure shared drive and complete a
                                        simple online briefing form.
                                    </div>
                                </div>
                                <div className="campaign-process__grid-item">
                                    <div className="text-p2 text-fw-medium text-clr-secondary">
                                        Step 3
                                    </div>
                                    <div className="text-h3 text-clr-primary text-fw-medium">
                                        Review and confirm.
                                    </div>
                                    <div className="text-p2 text-clr-secondary text-fw-regular">
                                        We review the job and come back to you with any questions,
                                        either by email or we’ll schedule a quick phone call to talk
                                        it through.
                                    </div>
                                </div>
                                <div className="campaign-process__grid-item">
                                    <div className="text-p2 text-fw-medium text-clr-secondary">
                                        Step 4
                                    </div>
                                    <div className="text-h3 text-clr-primary text-fw-medium">
                                        Fast turnaround.
                                    </div>
                                    <div className="text-p2 text-clr-secondary text-fw-regular">
                                        Our CAD experts complete the job, usually with 2-3 days (the
                                        more we get to know your business, the faster we can be). We
                                        send back the completed CAD designs for your review.
                                    </div>
                                </div>
                                <div className="campaign-process__grid-item">
                                    <div className="text-p2 text-fw-medium text-clr-secondary">
                                        Step 5
                                    </div>
                                    <div className="text-h3 text-clr-primary text-fw-medium">
                                        Any changes?
                                    </div>
                                    <div className="text-p2 text-clr-secondary text-fw-regular">
                                        Throughout the process, we maintain open lines of
                                        communication and flexibility, helping you for any
                                        adjustments or refinements along the way. We're committed to
                                        accommodating any changes to ensure the final outcome
                                        exceeds your expectations.
                                    </div>
                                </div>
                                <div className="campaign-process__grid-item">
                                    <div></div>
                                    <div className="text-h3 text-clr-primary text-fw-medium">
                                        Job delivered!
                                    </div>
                                    <div className="text-p2 text-clr-secondary text-fw-regular">
                                        Your success is our success, and we take pride in exceeding
                                        your expectations. Once everything meets your expectations,
                                        consider your job delivered with excellence.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="campaign-quotes container--mid-v2">
                        <div className="wrapper" style={{ background: `#F8F9FA` }}>
                            <div className="container">
                                <div className="campaign-quotes__grid">
                                    <h2 className="text-h2 text-clr-primary text-fw-medium">
                                        Project quotes or hourly rates
                                    </h2>
                                    <p className="text-p2 text-clr-secondary text-fw-regular">
                                        Structure the relationship to suit the worked. Work can be
                                        quoted job to job - or we offer a competitive hourly rate
                                        billed monthly, to speed up the process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="campaign-fields">
                        <div className="container container--mid-v2">
                            <h2 className="text-h2 text-clr-primary gap-4x text-fw-medium">
                                Experience across every aspect
                            </h2>
                            <div className="campaign-fields__grid">
                                <div className="campaign-fields__grid-item">
                                    <StaticImage
                                        src="../images/mechanical.png"
                                        alt="img"
                                        loading="lazy"
                                    />
                                    <h3 className="text-h3 text-clr-primary text-fw-medium gap-1x">
                                        Mechanical
                                    </h3>
                                    <p className="text-p2 text-clr-secondary text-fw-regular">
                                        Our mechanical engineering experts are experienced in
                                        designing, drafting, modeling, and analysis. We can deliver
                                        fabrication drawings for individual equipment right through
                                        to 3D layouts of entire plants.
                                    </p>
                                </div>
                                <div className="campaign-fields__grid-item">
                                    <StaticImage
                                        src="../images/pipe.png"
                                        alt="img"
                                        loading="lazy"
                                    />
                                    <h3 className="text-h3 text-clr-primary text-fw-medium gap-1x">
                                        Piping
                                    </h3>
                                    <p className="text-p2 text-clr-secondary text-fw-regular">
                                        Whether you need equipment modeling, industrial plant piping
                                        or civil, structural elements such as equipment foundations,
                                        platforms, ladders, railings, pipe racks, skid base frames –
                                        our team can help.
                                    </p>
                                </div>
                                <div className="campaign-fields__grid-item">
                                    <StaticImage
                                        src="../images/wire.png"
                                        alt="img"
                                        loading="lazy"
                                    />
                                    <h3 className="text-h3 text-clr-primary text-fw-medium gap-1x">
                                        Electrical
                                    </h3>
                                    <p className="text-p2 text-clr-secondary text-fw-regular">
                                        Our experts deliver precise manufacturing drawings including
                                        keyway positioning, backshell installation instructions,
                                        wire twisting procedures, splice locations, and other
                                        manufacturing specific information.
                                    </p>
                                </div>
                                <div className="campaign-fields__grid-item">
                                    <StaticImage
                                        src="../images/curve.png"
                                        alt="img"
                                        loading="lazy"
                                    />
                                    <h3 className="text-h3 text-clr-primary text-fw-medium gap-1x">
                                        Digitization
                                    </h3>
                                    <p className="text-p2 text-clr-secondary text-fw-regular">
                                        Transform legacy data into electronic formats. We upgrade/
                                        replace legacy data into highly precise parametric CAD
                                        format while ensuring product engineering data is captured
                                        in detail in line with standards.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container container--mid-v2">
                            <ImageSlider />
                        </div>
                    </section>
                </>
            )}
            <section>
                <div className="container container--mid-v2">
                    <CampaignInfo
                        list={data.pageData.serviceInfoList}
                        logo={data.pageData.serviceInfoLogo}
                        title={data.pageData.serviceInfoHeader}
                        engineering={engineering}
                        engineeringCad={engineeringCad}
                        steelStructure={steelStructure}
                        industricalAutomation={industricalAutomation}
                    />
                </div>
            </section>
            {(steelStructure && engineering && industricalAutomation) !==
                (!engineeringCad && !salesforce && !eamCampaginPage) && (
                <section className="eng-aus-campaign">
                    <div className="container container--mid-v2">
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                            Tool's we use
                        </h1>
                        {/* <h1 className="cad-subtitle-heading">
                            Empowering business through our software expertise
                        </h1> */}
                        <div className="software-sec-client-logo">
                            <div className="software-sec-client-logo__grid">
                                <StaticImage src="../images/cad-service/01.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/02.png" alt="client-logo" />
                                <StaticImage
                                    src="../images/cad-service/03.png"
                                    alt="pacific-logo"
                                />
                                <StaticImage src="../images/cad-service/04.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/05.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/06.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/07.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/08.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/09.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/10.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/11.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/12.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/13.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/14.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/15.png" alt="client-logo" />
                                <StaticImage src="../images/cad-service/16.png" alt="client-logo" />
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {steelStructure || industricalAutomation ? (
                <section className="campaign-engineering" style={{ backgroundColor: "#ECE1D4" }}>
                    <div className="container container--py">
                        {steelStructure ? (
                            <>
                                <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                                    Some of our steel structural design samples
                                </h1>
                                <div className="campaign-engineering-image">
                                    <div className="work-sample-sec__grid">
                                        <div className="work-sample-sec__grid-item">
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={StructuralOne}
                                                        alt="steel-structure"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={StructuralTwo}
                                                        alt="steel-structure"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        // src="../images/cad-service/structural-design-03.png"
                                                        src={StructuralThree}
                                                        alt="steel-structure"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={StructuralFour}
                                                        alt="steel-structure"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={StructuralFive}
                                                        alt="steel-structure"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={StructuralSix}
                                                        alt="steel-structure"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                                    Some of our Industrial automation design samples
                                </h1>
                                <div className="campaign-engineering-image">
                                    <div className="work-sample-sec__grid">
                                        <div className="work-sample-sec__grid-item">
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={IndustrialOne}
                                                        alt="industrial-automation"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={IndustrialTwo}
                                                        alt="industrial-automation"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        // src="../images/cad-service/structural-design-03.png"
                                                        src={IndustrialThree}
                                                        alt="industrial-automation"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={IndustrialFour}
                                                        alt="industrial-automation"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={IndustrialFive}
                                                        alt="industrial-automation"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="work-sample-sec__grid-item__content">
                                                <div className="work-sample-sec__grid-item__content-image">
                                                    <img
                                                        src={IndustrialSix}
                                                        alt="industrial-automation"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </section>
            ) : (
                engineering === !engineeringCad && (
                    <section
                        className="campaign-engineering"
                        style={{ backgroundColor: "#ECE1D4" }}
                    >
                        <div className="container container--py">
                            <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                                Some of our CAD work samples
                            </h1>
                            {/* <div className="image-swiper">
                            <EngineeringImgSlider />
                        </div> */}
                            <div className="campaign-engineering-image">
                                {/* <CadImageGrid /> */}
                                <div className="work-sample-sec__grid">
                                    <div className="work-sample-sec__grid-item">
                                        <div className="work-sample-sec__grid-item__content">
                                            <div className="work-sample-sec__grid-item__content-image">
                                                <img
                                                    src={ProductDesignOne}
                                                    alt="industrial-automation"
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="work-sample-sec__grid-item__content">
                                            <div className="work-sample-sec__grid-item__content-image">
                                                <img
                                                    src={ProductDesignTwo}
                                                    alt="industrial-automation"
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="work-sample-sec__grid-item__content">
                                            <div className="work-sample-sec__grid-item__content-image">
                                                <img
                                                    // src="../images/cad-service/structural-design-03.png"
                                                    src={ProductDesignThree}
                                                    alt="industrial-automation"
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="work-sample-sec__grid-item__content">
                                            <div className="work-sample-sec__grid-item__content-image">
                                                <img
                                                    src={ProductDesignFour}
                                                    alt="industrial-automation"
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="work-sample-sec__grid-item__content">
                                            <div className="work-sample-sec__grid-item__content-image">
                                                <img
                                                    src={ProductDesignFive}
                                                    alt="industrial-automation"
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        <div className="work-sample-sec__grid-item__content">
                                            <div className="work-sample-sec__grid-item__content-image">
                                                <img
                                                    src={ProductDesignSix}
                                                    alt="industrial-automation"
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            )}
            {salesforce && (
                <section>
                    <div className="container container--mid-v2 ">
                        <SalesforceInfo />
                    </div>
                </section>
            )}
            {(steelStructure || engineering || industricalAutomation) === !engineeringCad ? (
                <section className="engineering-testimonial">
                    <div className="container container--mid-v2">
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-4x">
                            Client Testimonials
                        </h1>
                        <Testimonials testimonialData={data.pageData.testimonial} />
                    </div>
                </section>
            ) : (
                <section>
                    <div className="container container--mid-v2">
                        <SingleTestimonial testimonial={data.pageData.testimonial} />
                    </div>
                </section>
            )}
            <section>
                <div className="container container--mid-v2">
                    <ServiceLocation
                        description={data.pageData.teamDescription}
                        logo={data.pageData.teamLeaderLogo}
                        name={data.pageData.teamLeader}
                        designation={data.pageData.teamLeaderInfo}
                        locationImage={data.pageData.teamLocation}
                        engineeringCad={engineeringCad}
                        engineeringSlug={engineering}
                        campaignMembers={data.pageData.campaignMembers}
                        handleModel={handleModel}
                        steelStructure={steelStructure}
                        salesforce={salesforce}
                        industricalAutomation={industricalAutomation}
                    />
                </div>
            </section>
            {(steelStructure || engineering || industricalAutomation) === !engineeringCad ? (
                <section className="container--mid-v2">
                    <div
                        style={{
                            backgroundColor: `#F8F9FA`,
                        }}
                    >
                        <div className="container engineering">
                            <Statistics
                                stats={data.pageData.statistics}
                                title="Partner with experts"
                            />
                        </div>
                    </div>
                </section>
            ) : (
                <section className="container--mid-v2">
                    <div
                        style={{
                            backgroundColor: `#F8F9FA`,
                        }}
                    >
                        <div className="container ">
                            <Statistics
                                stats={data.pageData.statistics}
                                title="Partner with experts"
                            />
                        </div>
                    </div>
                </section>
            )}
            {(steelStructure || engineering || industricalAutomation) === !engineeringCad && (
                <section className="cad-campaign-engagement">
                    <div className="container container--mid-v2">
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-3x">
                            Engagement model
                        </h1>
                        <div className="cad-campaign-engagement__grid">
                            <div className="cad-campaign-engagement__grid-item">
                                <img
                                    // src="../images/cad-service/staff-aug.svg"
                                    src={EngModalOne}
                                    alt="staff-aug"
                                    loading="lazy"
                                />
                                <h3 className="text-h3 text-clr-primary text-fw-regular gap-02x ">
                                    Time & Material
                                </h3>
                                <p className="text-p4 text-clr-secondary text-fw-medium flex flex-jc-c flex-ai-c">
                                    When the project is of shorter duration and you are looking for
                                    a quick turnaround this pricing model makes it easy. We bill you
                                    based on the actual man hours of our designers.
                                </p>
                            </div>
                            <div className="cad-campaign-engagement__grid-item">
                                <img src={EngModalTwo} alt="staff-aug" loading="lazy" />
                                <h3 className="text-h3 text-clr-primary text-fw-regular gap-02x ">
                                    Full Time Equivalent
                                </h3>
                                <p className="text-p4 text-clr-secondary text-fw-medium flex flex-jc-c flex-ai-c">
                                    When your requirement is ongoing and you need flexibility with
                                    respect to the effort and team size, this model works best. We
                                    will bill you every month on a retainer basis.
                                </p>
                            </div>
                            <div className="cad-campaign-engagement__grid-item">
                                <img src={EngModalThree} alt="staff-aug" loading="lazy" />
                                <h3 className="text-h3 text-clr-primary text-fw-regular gap-02x ">
                                    Fixed Package
                                </h3>
                                <p className="text-p4 text-clr-secondary text-fw-medium flex flex-jc-c flex-ai-c">
                                    When you know exactly what you want from us and have a fixed
                                    timeline we suggest this pricing model. It's a one time cost for
                                    a clearly defined scope
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {(steelStructure || engineering || industricalAutomation) === !engineeringCad && (
                <section className="campaign-expertise">
                    <div className="container container--mid-v2">
                        <div className="text">
                            <h1 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                                Industries we serve
                            </h1>
                            <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                                With extensive experience we have been serving numerous global
                                clients across different industry sectors.
                            </p>
                        </div>
                        <div className="industries-sec__grid">
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryOne}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Agriculture</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryTwo}
                                    // src="../images/cad-service/Driven-1.png"
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Food & Bevarage</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryThree}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Heavy Machinery</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryFour}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Industrial Automation</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryFive}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Steel Structure</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustrySix}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Automotive</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustrySeven}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Oil & Natural Gas</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryEight}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Water Treatment Plant</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryNine}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">
                                    Packaging & Material handling
                                </p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryTen}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Machinery Manufacturing</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryEleven}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">MariTime</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryTwelve}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Power</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryThirteen}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Medical</p>
                            </div>
                            <div className="industries-sec__grid-item gap-1x">
                                <img
                                    src={IndustryFourteen}
                                    placeholder="blurred"
                                    alt="industries-icons"
                                    loading="lazy"
                                />
                                <p className="text-p2 text-clr-primary">Bridge</p>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {/* FAQ content */}
            {(steelStructure || engineering || industricalAutomation) === !engineeringCad && (
                <>
                    <section className="engineering-faq">
                        <div className="container container--mid-v2">
                            <Faq faq={data?.pageData?.campaignFaq} engineering />
                        </div>
                    </section>
                </>
            )}
            {salesforce && (
                <section className="service-partners" style={{ paddingBottom: "0" }}>
                    <div className="container container--last">
                        <SalesforceExpertise />
                    </div>
                </section>
            )}
            {salesforce ? (
                <section className="salesforce-cta">
                    <div className="container">
                        <SalesforceContactBlock />
                    </div>
                </section>
            ) : steelStructure || engineering || industricalAutomation === !engineeringCad ? (
                <section className="cad-service-contact-us">
                    <div className="container container--last">
                        <div className="cad-service-contact-us-grid">
                            <div className="cad-service-contact-us-grid-item">
                                <div className="cad-subtitle gap-05x">
                                    <h3 className="cad-subtitle-text gap-05x">GET IN TOUCH</h3>
                                    {steelStructure ? (
                                        <h1 className="cad-subtitle-heading">
                                            Looking to Outsource <br />
                                            Steel Structure projects?
                                        </h1>
                                    ) : industricalAutomation ? (
                                        <h1 className="cad-subtitle-heading">
                                            Looking to Outsource <br />
                                            Industrial Automation?
                                        </h1>
                                    ) : (
                                        <h1 className="cad-subtitle-heading">
                                            Looking to Outsource <br />
                                            CAD projects?
                                        </h1>
                                    )}
                                    <p className="text-p2 text-clr-secondary gap-4x">
                                        Once you submit this form, expect to hear back from us
                                        within 24 hours
                                    </p>
                                </div>
                                <h2 className="gap-1x">REACH US AT:</h2>
                                <div className="cad-service-contact-us-grid-item__contact-info">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 28 28"
                                        fill="none"
                                    >
                                        <path
                                            d="M3.73143 20C3.23786 20 2.82607 19.835 2.49607 19.505C2.16607 19.175 2.00071 18.7629 2 18.2686V6.73143C2 6.23786 2.16536 5.82607 2.49607 5.49607C2.82679 5.16607 3.23821 5.00071 3.73036 5H19.5554C20.0482 5 20.4596 5.16536 20.7896 5.49607C21.1196 5.82679 21.285 6.23857 21.2857 6.73143V18.2696C21.2857 18.7625 21.1204 19.1743 20.7896 19.505C20.4589 19.8357 20.0475 20.0007 19.5554 20H3.73143ZM11.6429 12.6243L3.07143 7.01964V18.2696C3.07143 18.4618 3.13321 18.6196 3.25679 18.7432C3.38036 18.8668 3.53857 18.9286 3.73143 18.9286H19.5554C19.7475 18.9286 19.9054 18.8668 20.0289 18.7432C20.1525 18.6196 20.2143 18.4614 20.2143 18.2686V7.01857L11.6429 12.6243ZM11.6429 11.4286L19.8843 6.07143H3.40143L11.6429 11.4286ZM3.07143 7.01964V6.07143V18.2696C3.07143 18.4618 3.13321 18.6196 3.25679 18.7432C3.38036 18.8668 3.53857 18.9286 3.73143 18.9286H3.07143V7.01964Z"
                                            fill="#AF8169"
                                        />
                                        <path
                                            d="M3.07143 7.01964L11.6429 12.6243L20.2143 7.01857V18.2686C20.2143 18.4614 20.1525 18.6196 20.0289 18.7432C19.9054 18.8668 19.7475 18.9286 19.5554 18.9286H3.73143M3.07143 7.01964V18.2696M3.07143 7.01964V6.07143V18.2696M3.07143 7.01964V18.9286H3.73143M3.07143 18.2696C3.07143 18.4618 3.13321 18.6196 3.25679 18.7432C3.38036 18.8668 3.53857 18.9286 3.73143 18.9286M3.73143 20C3.23786 20 2.82607 19.835 2.49607 19.505C2.16607 19.175 2.00071 18.7629 2 18.2686V6.73143C2 6.23786 2.16536 5.82607 2.49607 5.49607C2.82679 5.16607 3.23821 5.00071 3.73036 5H19.5554C20.0482 5 20.4596 5.16536 20.7896 5.49607C21.1196 5.82679 21.285 6.23857 21.2857 6.73143V18.2696C21.2857 18.7625 21.1204 19.1743 20.7896 19.505C20.4589 19.8357 20.0475 20.0007 19.5554 20H3.73143ZM11.6429 11.4286L19.8843 6.07143H3.40143L11.6429 11.4286Z"
                                            stroke="#AF8169"
                                            stroke-width="0.2"
                                        />
                                    </svg>
                                    <a
                                        href="mailto:sales@sedinengineering.com"
                                        className="text-p3 text-clr-secondary"
                                    >
                                        sales@sedinengineering.com
                                    </a>
                                </div>

                                <div>
                                    {/* <p className="text-p2 text-fw-medium text-clr-primary">
                                        Canada:
                                    </p> */}
                                    <div className="cad-service-contact-us-grid-item__contact-info">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                        >
                                            <g clip-path="url(#clip0_1_2119)">
                                                <path
                                                    d="M14.9437 3.75C16.2159 4.09141 17.3758 4.76142 18.3072 5.69279C19.2386 6.62416 19.9086 7.78411 20.25 9.05625"
                                                    stroke="#AF8169"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M14.1656 6.64648C14.9308 6.84968 15.6286 7.25153 16.1883 7.8113C16.7481 8.37107 17.1499 9.06887 17.3531 9.83398"
                                                    stroke="#AF8169"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M8.67188 11.6998C9.44365 13.2936 10.7324 14.579 12.3281 15.3467C12.4458 15.4024 12.576 15.4265 12.7059 15.4167C12.8358 15.4068 12.9608 15.3633 13.0688 15.2904L15.4125 13.7248C15.5161 13.6546 15.6357 13.6117 15.7603 13.6002C15.8849 13.5888 16.0104 13.609 16.125 13.6592L20.5125 15.5435C20.6625 15.606 20.7877 15.7159 20.869 15.8565C20.9504 15.9971 20.9832 16.1606 20.9625 16.3217C20.8234 17.407 20.2937 18.4046 19.4723 19.1276C18.6509 19.8506 17.5943 20.2495 16.5 20.2498C13.1185 20.2498 9.87548 18.9065 7.48439 16.5154C5.0933 14.1243 3.75 10.8813 3.75 7.49979C3.75025 6.40553 4.1492 5.34886 4.87221 4.5275C5.59522 3.70613 6.59275 3.17635 7.67813 3.03729C7.83922 3.01659 8.00266 3.04943 8.14326 3.13074C8.28386 3.21206 8.39384 3.33733 8.45625 3.48729L10.3406 7.88416C10.3896 7.99699 10.4101 8.12014 10.4003 8.24275C10.3905 8.36537 10.3507 8.48369 10.2844 8.58729L8.71875 10.9685C8.64905 11.0762 8.60814 11.2 8.59993 11.328C8.59172 11.4561 8.6165 11.5841 8.67188 11.6998Z"
                                                    stroke="#AF8169"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1_2119">
                                                    <rect width="24" height="24" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <a
                                            className="text-p3 text-clr-secondary"
                                            href="tel: +61 411 333 390"
                                        >
                                            Call Us: +61 411 333 390
                                        </a>
                                    </div>
                                    <div className="cad-service-contact-us-grid-item__contact-info">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M10.9997 21.1889L11.7209 20.3763C12.5391 19.4391 13.2751 18.55 13.93 17.7043L14.4706 16.9911C16.7277 13.95 17.8569 11.5363 17.8569 9.75228C17.8569 5.94428 14.7871 2.85742 10.9997 2.85742C7.21229 2.85742 4.14258 5.94428 4.14258 9.75228C4.14258 11.5363 5.27172 13.95 7.52886 16.9911L8.06944 17.7043C9.00365 18.9013 9.98106 20.0628 10.9997 21.1889Z"
                                                stroke="#AF8169"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M10.9997 12.5717C12.5777 12.5717 13.8569 11.2925 13.8569 9.71456C13.8569 8.13661 12.5777 6.85742 10.9997 6.85742C9.42176 6.85742 8.14258 8.13661 8.14258 9.71456C8.14258 11.2925 9.42176 12.5717 10.9997 12.5717Z"
                                                stroke="#AF8169"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <p className="text-p3 text-clr-secondary">
                                            Level 19, 15 William Street. Melbourne,VIC, 3000
                                            Australia
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="cad-service-contact-us-grid-item__form">
                                <CadServiceForm />
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="campaign-navigation">
                    <div className="container">
                        <NavigateBlockV3
                            title={data.pageData.serviceCallHeader}
                            engineering={engineering}
                            engineeringCad={engineeringCad}
                            handleModel={handleModel}
                            steelStructure={steelStructure}
                        />
                    </div>
                </section>
            )}
            {(steelStructure || engineering || industricalAutomation) === !engineeringCad && (
                <>
                    <CSSTransition in={showModal} timeout={300} classNames="fade" unmountOnExit>
                        <EngineeringLeadForm showModal={showModal} setShowModal={setShowModal} />
                    </CSSTransition>
                </>
            )}
            <section className="campaign-footer">
                <div className="container">
                    <p className="text-p6 text-clr-secondary text-fw-regular">
                        © 2024 Sedin Technologies Inc
                    </p>
                </div>
            </section>
        </>
    );
};
export const query = graphql`
    query campaignPageQuery($id: String) {
        pageData: contentfulCampaignLanding(id: { eq: $id }) {
            title
            description {
                description
            }
            formHeader {
                formHeader
            }
            formHeading {
                raw
            }
            metTitle
            logo {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                title
                file {
                    url
                }
            }
            banner {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
            serviceInfoLogo {
                gatsbyImageData(width: 400, quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                title
            }
            testimonial {
                id
                personDesignation
                personName
                profileImage {
                    gatsbyImageData(
                        quality: 100
                        sizes: "250"
                        layout: CONSTRAINED
                        placeholder: BLURRED
                    )
                    title
                }
                testimonialType
                testimonialStatement {
                    testimonialStatement
                }
            }
            statistics {
                name
                value
            }
            teamDescription
            teamLeader
            teamLeaderInfo
            teamLeaderLogo {
                gatsbyImageData(
                    quality: 100
                    sizes: "80"
                    width: 80
                    placeholder: BLURRED
                    layout: CONSTRAINED
                )
                title
            }
            teamLocation {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                title
            }
            serviceCallHeader
            facilities {
                title
                icon {
                    gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                    title
                    file {
                        url
                    }
                }
            }
            serviceInfoList {
                raw
            }
            serviceInfoHeader
            campaignClientLogo {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
                title
                file {
                    url
                }
            }
            campaignFaq {
                title
                description {
                    description
                }
            }
            campaignDescPoints {
                raw
            }
            campaignMembers {
                name
                designation
                colorLogo {
                    gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
                    title
                    file {
                        url
                    }
                }
            }
        }
    }
`;

export default Campaign;
